<template>
  <div class="microsoft"></div>
</template>

<script>
  export default {
    name: "LoginAzure",
    props: {
      redirect: String
    },
    data() {
      return {
        token: null
      };
    },
    mounted() {
      const baseToken = this.$route.hash;
      this.token = baseToken.split("&")[0].substring(baseToken.split("&")[0].indexOf("=") + 1);
      console.log("this.token", this.token);
      this.login();
    },
    methods: {
      login() {
        const appWindow = window;
        this.$store.dispatch("auth/LOGINAZURE", { token: this.token, appWindow });
      }
    }
  };
</script>

<style scoped>
  .microsoft {
    position: absolute;
    right: 20px;
    bottom: 20px;
    width: 140px;
    height: 60px;
    background: url("../../assets/esign-icon.png") no-repeat;
    background-size: contain;
  }
</style>
